<template>
    <div>
        <report-add v-if="baseTable" />
        <report-list v-else />
    </div>
</template>

<script>
import ReportList from "../components/reports/ReportList.vue"
import ReportAdd from "../components/reports/ReportAdd.vue"

export default {
  name: 'Reports',
  components: {
      ReportList,
      ReportAdd
  },
  methods: {
  },
  computed: {
      baseTable() {
          return this.$store.state.reports.base_table;
      }
  }
}
</script>