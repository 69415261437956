<template>
<b-container>
    <b-overlay :show="reportLoading">
    <b-alert
        v-if="!modalAlert"
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div class="avo-header-text">
        <span @click="backToAllReports()" class="mr-2" style="cursor:pointer;color:var(--gray);">
        All Reports
        </span>
        <span class="mr-2">
            <b-icon icon="chevron-right" />
        </span>
        <span>
            <u>Custom Reports</u>
        </span>
    </div>
    <div class="avo-page-header-text mt-2 mb-2">
        <span v-if="!preview_report">
        Arranging Columns
        </span>
        <span v-else>
        Preview Report
        </span>
    </div>
    <div class="d-flex">
        <div v-if="!preview_report" style="width:25%;max-height:750px;overflow-y:scroll">
            <b-card>
                <div class="d-flex justify-content-between">
                    <div class="mt-2">
                        Data Source: {{baseTable['name']}}
                    </div>
                    <div>
                        <b-button v-b-modal.report-settings class="avo-primary-btn">Edit Settings</b-button>
                        <report-settings-modal />
                    </div>
                </div>
                <hr style="margin-right:-20px;margin-left:-20px">
                <div>
                    Browse by:
                    <b-dropdown :text="selectedTable['name']">
                        <span v-for="(table,index) in allTables" :key=index>
                        <b-dropdown-item @click="selectedTable = table">{{table['name']}}</b-dropdown-item>
                        </span>
                    </b-dropdown>
                </div>
                <div>
                <draggable v-model="selectableColumns" group="column">
                <div v-for="(column,index) in selectableColumns" :key="index" class="d-flex mb-1"
                style='border:1px solid #9CA3AD;padding:5px;cursor:pointer'>
                <div class="mr-2">
                    <b-icon icon="arrows-move" />
                </div>
                <div>
                    {{column.name}}
                </div>
                </div>
                </draggable>
                </div>
            </b-card>
        </div>
        <div :class="!preview_report ? 'pl-4 flex-fill': 'flex-fill'" :style="!preview_report ? 'width:75%' : 'width:100%'">
            <div class="d-flex justify-content-between">
                <div>
                    <b-dropdown v-if="!preview_report" text="Filters" style="border:solid 1px #9CA3AD" ref="dropdown">
                        <div class="m-2" style="width:50vw">
                            <div class="avo-header-text ml-2">
                                Active Filters
                            </div>
                            <div v-if="(active_filters_list.length == 0)&&(!start_date)&&(!end_date)" style="text-align:center">
                                None
                            </div>
                            <div v-if="start_date" style="text-align:center">
                                <span v-if="reportMap[baseTable['value']]['date']['date_key']">
                                    {{reportMap[baseTable['value']]['date']['date_key']}} after {{start_date}}
                                </span>
                                <span v-else-if="reportMap[baseTable['value']]['date']['month_key'] && reportMap[baseTable['value']]['date']['year_key']">
                                    {{reportMap[baseTable['value']]['date']['month_key']}} after Month/Year of {{start_date}}
                                </span>
                                <span v-else-if="reportMap[baseTable['value']]['date']['year_key']">
                                    {{reportMap[baseTable['value']]['date']['year_key']}} after Year of {{start_date}}
                                </span>
                                <span v-else>
                                    Start Date after {{start_date}}
                                </span>
                            </div>
                            <div v-if="end_date" style="text-align:center">
                                <span v-if="reportMap[baseTable['value']]['date']['date_key']">
                                    {{reportMap[baseTable['value']]['date']['date_key']}} before {{end_date}}
                                </span>
                                <span v-else-if="reportMap[baseTable['value']]['date']['month_key'] && reportMap[baseTable['value']]['date']['year_key']">
                                    {{reportMap[baseTable['value']]['date']['month_key']}} before Month/Year of {{end_date}}
                                </span>
                                <span v-else-if="reportMap[baseTable['value']]['date']['year_key']">
                                    {{reportMap[baseTable['value']]['date']['year_key']}} before Year of {{end_date}}
                                </span>
                                <span v-else>
                                    End Date after {{end_date}}
                                </span>
                            </div>
                            <div v-for="(filter, index) in active_filters_list" :key="index" style="text-align:center">
                                {{filter}}
                            </div>
                        </div>
                        <hr>
                        <div class="m-2" style="width:50vw">
                            <div class="d-flex justify-content-between">
                                <div class="avo-header-text ml-2">
                                    Date Range
                                </div>
                            <div class="mr-2">
                                <u @click="resetDateRange()" style="cursor:pointer">Reset</u>
                            </div>
                            </div>
                            <div class="d-flex justify-content-around">
                                <div>
                                    <b-form-group id="start-date" label="Start Date">
                                        <b-input v-model="start_date" type="date"></b-input>
                                    </b-form-group>
                                </div>
                                <div>
                                    <b-form-group id="start-date" label="End Date">
                                        <b-input v-model="end_date" type="date"></b-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between m-2" style="width:50vw">
                            <div class="avo-header-text ml-2">
                                Custom Filters
                            </div>
                            <div class="mr-2">
                                <u @click="resetFilters()" style="cursor:pointer">Reset</u>
                            </div>
                        </div>
                        <div>
                            <div v-for="(filter, index) in filters_list" :key="index" class="d-flex justify-content-between m-2">
                                <div class="d-flex flex-fill justify-content-around ml-2">
                                    <div class="mr-2" style="width:33.33%">
                                    <b-form-select v-model="filter.column" :options="filterColumns">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>Property</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    </div>
                                    <div style="width:33.3%">
                                    <b-form-select v-model="filter.op" 
                                    :disabled="!filter.column"
                                    :options="!filter.column || (filter.column['type'] != 'str' && filter.column['type'] != 'enum') ? 
                                    operators : (filter.column['type'] == 'str' ? str_operators : enum_operators)"
                                    text-field="name" value-field="value">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>Operator</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    </div>
                                    <div class="ml-2" style="width:33.3%">
                                        <b-form-input v-if="!filter.column || (filter.op == 'IS NULL' || filter.op == 'IS NOT NULL')" disabled placeholder="Value"></b-form-input>
                                        <b-form-input v-else-if="filter.column['type'] == 'int' || filter.column['type'] == 'float'" v-model="filter.value" type="number" placeholder="Value"></b-form-input>
                                        <b-input-group v-else-if="filter.column['type'] == 'dollar'" prepend="$"><b-form-input v-model="filter.value" type="number" placeholder="Value"></b-form-input></b-input-group>
                                        <b-form-input v-else-if="filter.column['type'] == 'date'" v-model="filter.value" type="date" placeholder="Value"></b-form-input>
                                        <b-form-select v-else-if="filter.column['type'] == 'enum'" v-model="filter.value" :options="filter.column.map">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Value</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-input v-else v-model="filter.value" placeholder="Value"></b-form-input>
                                    </div>
                                </div>
                                <div class="mr-2" style="margin-top:3px">
                                    <b-icon @click="removeFilter(index)" @click.stop font-scale="2" icon="x" style="cursor:pointer"></b-icon>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-4 m-2">
                            <div>
                                <b-button class="avo-basic-btn ml-2" style="text-align:center;display:inline" @click="addFilter()">Add Custom Filter</b-button>
                            </div>
                            <div>
                                <b-button class="avo-primary-btn" style="text-align:center;display:inline;margin-right:40px" @click="applyFilters()">Apply Filters</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                    <b-button v-else class="avo-basic-btn" @click="preview_report = false;currentPage = 1"><b-icon icon="chevron-left" class="mr-2" />Customize Report</b-button>
                </div>
                <div>
                    <b-button v-if="preview_report" class="avo-primary-btn mr-2" v-b-modal.save-report-modal @click="export_report = true">Export Report</b-button>
                    <b-button v-if="preview_report" v-b-modal.save-report-modal @click="export_report = false" class="avo-primary-btn">Save Report</b-button>
                    <b-modal id="save-report-modal" hide-footer hide-header size="md">
                        <div class="avo-header-text" style="text-align:center">
                            Save{{export_report ? " and Export" : " "}} Report
                        </div>
                        <hr class="mt-2">
                        <div class="mt-2">
                            <b-alert
                                v-if="modalAlert"
                                v-model="showAlert"
                                variant="danger"
                                dismissible>
                                <b>{{alertMessage}}</b>
                            </b-alert>
                        </div>
                        <div>
                            <b-form-group id="title" label="Title">
                                <b-input-group>
                                <b-form-input v-model="reportTitle"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div>
                            <b-form-group id="description" label="Description">
                                <b-input-group>
                                <b-form-input v-model="reportDescription"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div>
                                <b-button @click="hideSaveReportModal()" class="avo-basic-btn">Cancel</b-button>
                            </div>
                            <div class="ml-2">
                                <b-button @click="submitReport()" class="avo-primary-btn">Submit Report</b-button>
                            </div>
                        </div>
                    </b-modal>
                    <b-button v-if="!preview_report" class="avo-primary-btn" @click="executeStmt()">Run Report</b-button>
                </div>
            </div>
            <div class="mt-2" style="max-height:500px;overflow-x:scroll;overflow-y:scroll;border:1px solid #9CA3AD">
                <b-table-simple bordered style="height:500px;margin:0px;padding:0px">
                    <b-thead>
                        <draggable v-model="selectedColumns" group="column" tag="b-tr">
                                <b-th v-for="(column, index) in selectedColumns" :key="index" scope="col">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        {{ column.name }}
                                    </div>
                                    <div v-if="sortable && !preview_report" @click="handleSort(column.label)" style="cursor:pointer">
                                        <b-icon :icon="((column.label == sort_by) && !sort_ascending) ? 'sort-down' : 'sort-up'" :style="column.label == sort_by ? '' : 'opacity:25%'" />
                                    </div>
                                </div>
                                </b-th>
                        </draggable>
                    </b-thead>
                    <b-tbody>
                        <b-tr b-td v-for="(result, row_index) in results" :key="row_index">
                            <b-td v-for="(column, column_index) in selectedColumns" :key="column_index">
                                <span v-if="results.length > 0">
                                    {{ formatResult(results, row_index, column) }}
                                </span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div v-if="preview_report" class="mt-4">
                <b-pagination
                v-if="results.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                ></b-pagination>
            </div>
        </div>
    </div>
    </b-overlay>
</b-container>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import { mapState } from 'vuex'

import ReportSettingsModal from './ReportSettingsModal.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ReportAdd',
  components: {
      ReportSettingsModal,
      draggable
  },
  mixins: [alertsMixin],
  data(){
      return {
        selectedTable: null,
        selectableColumns: [],
        selectedColumns: [],
        filterColumns: [],
        allTables: [],
        filters_list: [],
        active_filters_list: [],
        filter_obj: {
            column: null,
            op: null,
            value: null,
        },
        filters: "",
        operators: [
            {"name": "EQUAL TO", "value": "="},
            {"name": "NOT EQUAL TO", "value": "!="},
            {"name": "GREATER THAN", "value": ">"},
            {"name": "GREATER THAN OR EQUAL TO", "value": ">="},
            {"name": "LESS THAN", "value": "<"},
            {"name": "LESS THAN OR EQUAL TO", "value": "<="},
            {"name": "IS NOT NULL", "value": "IS NOT NULL"},
            {"name": "IS NULL", "value": "IS NULL"}
        ],
        str_operators: [
            {"name": "EQUAL TO", "value": "="},
            {"name": "NOT EQUAL TO", "value": "!="},
            {"name": "SEARCH FOR TEXT", "value": "LIKE"},
            {"name": "IS NOT NULL", "value": "IS NOT NULL"},
            {"name": "IS NULL", "value": "IS NULL"}
        ],
        enum_operators: [
            {"name": "EQUAL TO", "value": "="},
            {"name": "NOT EQUAL TO", "value": "!="},
            {"name": "IS NOT NULL", "value": "IS NOT NULL"},
            {"name": "IS NULL", "value": "IS NULL"}
        ],
        start_date: null,
        end_date: null,
        sort_by: null,
        sort_ascending: true,
        preview_report: false,
        results: [],
        reportTitle: "",
        reportDescription: "",
        export_report: false,
        reportLoading: false,
        currentPage: 1,
        totalRows: 0,
        perPage: 50,

        modalAlert: false,
      }
  },
  methods: {
    setupData(){
      this.selectedTable = this.baseTable;
      var primary_key = this.reportMap[this.selectedTable['value']]['primary_key'];
      this.resetFilters();
      this.resetDateRange();
      this.results = 0
      this.selectedColumns = [];
      this.allTables = [this.selectedTable];
      for (var key in this.reportMap[this.selectedTable['value']]["joins"]){
          if (this.reportMap[key]){
              this.allTables.push(
                  {
                      'name': this.reportMap[key]["name"],
                      'value': key,
                      'sub_table': null
                  }
              )
          }
      }
      if (this.previousStartDate){
          this.start_date = this.previousStartDate;
      }
      if (this.previousEndDate){
          this.end_date = this.previousEndDate;
      }
      if (this.previousColumns.length > 0){
        this.selectedColumns = this.previousColumns;
      }
      else{
        if (primary_key){
            var column = this.reportMap[this.selectedTable['value']]['columns'][primary_key];
            column['key'] = primary_key;
            this.selectedColumns = [column];
        }
        else{
            if (this.reportMap[this.selectedTable['value']]['initial_selected_columns']){
                for (var i = 0; i < this.reportMap[this.selectedTable['value']]['initial_selected_columns'].length; i++){
                    column = this.reportMap[this.selectedTable['value']]['columns'][this.reportMap[this.selectedTable['value']]['initial_selected_columns'][i]];
                    column['key'] = this.reportMap[this.selectedTable['value']]['initial_selected_columns'][i];
                    this.selectedColumns.push(column);
                }
            }
            else{
                var columns = this.reportMap[this.selectedTable['value']]['columns'];
                this.selectedColumns = [];
                //select all columns initially for special report types
                for (let key in columns){
                    column = columns[key];
                    column['key'] = key;
                    this.selectedColumns.push(column);
                }
            }
        }
      }
      if (this.previousFilters.length > 0){
        this.filters_list = this.previousFilters;
        this.applyFilters();
      }
      if (this.previousOrderBy){
        this.handleSort(this.previousOrderBy.split(' ')[0]);
        if (this.previousOrderBy.split(' ').pop() == "DESC"){
            this.sort_ascending = false;
        }
        else{
            this.sort_ascending = true;
        }
      }
      else if (primary_key){
        this.handleSort(this.reportMap[this.selectedTable['value']]['columns'][primary_key]['label']);
      }
    },
    resetFilters(){
        this.filters = "";
        this.filters_list = [Object.assign({}, this.filter_obj)];
        this.active_filters_list = [];
    },
    addFilter(){
        this.filters_list.push(Object.assign({}, this.filter_obj));
    },
    removeFilter(index){
        this.filters_list.splice(index, 1);
    },
    applyFilters(){
        this.filters = "";
        this.active_filters_list = [];
        var op_text;
        for (var i = 0; i < this.filters_list.length; i++){
            if (this.filters_list[i].column != null && this.filters_list[i].op != null && 
            (this.filters_list[i].value != null || this.filters_list[i].op == "IS NULL" || this.filters_list[i].op == "IS NOT NULL")){
                var this_filter = [this.filters_list[i].column['key'], this.filters_list[i].op, this.filters_list[i].value].join(",,");
                if (this.filters.length > 0){
                    this.filters += ";"
                }
                this.filters += this_filter;
                if (this.filters_list[i].op == "!="){
                    op_text = "not ="
                }
                else if (this.filters_list[i].op == "LIKE"){
                    op_text = "search for"
                }
                else{
                    op_text = this.filters_list[i].op;
                }
                this.active_filters_list.push(this.filters_list[i].column.name + ' ' + op_text + ' ' + (this.filters_list[i].value ? this.filters_list[i].value : ''));
            }
        }
    },
    handleSort(label){
        if (label != this.sort_by){
            this.sort_by = label;
            this.sort_ascending = true;
        }
        else{
            this.sort_ascending = !this.sort_ascending;
        }
    },
    backToAllReports(){
        this.$store.commit("reports/clearAll");
    },
    executeStmt(){
        this.reportLoading = true;
        var data = {
            "base_table": this.baseTable['value'],
            "columns": (this.selectedColumns.length > 0 ? this.selectedColumns.map(obj => (obj['path'] + ":" + obj['key'])).join(",,") : null),
            "filters": this.filters,
            "order_by": this.order_by,
            "start_date": this.start_date,
            "end_date": this.end_date,
            "page": this.currentPage,
            "per_page": this.perPage
        }

        const url = make_url("/reports/stmt/execute");
        const vm = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: data
        }).then(function(response){
            if (response) {
                vm.results = response.data.reports;
                vm.totalRows = response.data.pagination.total;
                vm.perPage = response.data.pagination.per_page;
                vm.preview_report = true;
            }
        }).catch(function(error){
            vm.showError(error);
        }).finally(()=>{
            vm.reportLoading = false;
        });
        
        return promise;
        
    },
    formatResult(results, row_index, column){
        if (results[row_index][column.label] == null){
            return "-"
        }
        if (column.type == "enum"){
            return column.map[results[row_index][column.label]];
        }
        if (column.type == "dollar"){
            return "$" + Number(results[row_index][column.label]).toLocaleString();
        }
        if (column.type == "int" || column.type == "float"){
            return Number(results[row_index][column.label]).toLocaleString();
        }
        return results[row_index][column.label];
    },
    submitReport(){
        if (this.reportTitle.length == 0){
            this.showError("Please enter a title", true);
            return
        }
        var data = {
            "title": this.reportTitle,
            "description": this.reportDescription,
            "worker_id": this.worker_id,
            "base_table": this.baseTable['value'],
            "columns": (this.selectedColumns.length > 0 ? this.selectedColumns.map(obj => (obj['path'] + ":" + obj['key'])).join(",,") : null),
            "filters": this.filters,
            "order_by": this.order_by,
            "start_date": this.start_date,
            "end_date": this.end_date,
            "standard": false,
        }
        var method;
        var url;
        if (this.currentReportID){
            method = "put",
            url = make_url("/reports/" + this.currentReportID).toString();
        }
        else{
            method = "post";
            url = make_url("/reports");
        }
        const vm = this;
        const promise = authenticated_request({
            method: method,
            url: url,
            data: data
        }).then(function(response){
            if (response) {
                if (vm.export_report){
                    vm.$store.commit("reports/selectReport", (vm.currentReportID ? vm.currentReportID : response.data))
                }
                vm.backToAllReports();
            }
        }).catch(function(error){
            vm.showError(error, true);
        });

        return promise;
    },
    hideSaveReportModal(){
        if (this.currentReportTitle){
            this.reportTitle = this.currentReportTitle;
        }
        else{
            this.reportTitle = "";
        }
        if (this.currentReportDescription){
            this.reportDescription = this.currentReportDescription;
        }
        else{
            this.reportDescription = "";
        }
        this.hideError();
        this.$bvModal.hide('save-report-modal')
    },
    resetDateRange(){
        this.start_date = null;
        this.end_date = null;
    },
    showError(alertMessage, modalAlert){
        this.modalAlert = modalAlert;
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.modalAlert = false;
        this.setShowAlert(false);
    },
  },
  computed: {
    ...mapState('reports', {
        reportMap: 'report_map',
        baseTable: 'base_table',
        previousColumns: 'columns',
        previousFilters: 'filters',
        previousOrderBy: 'order_by',
        previousStartDate: 'start_date',
        previousEndDate: 'end_date',
        currentReportID: 'report_id',
        currentReportTitle: 'report_title',
        currentReportDescription: 'report_description',
    }),
    worker_id: function(){
        return localStorage.getItem("worker_id");
    },
    order_by: function(){
        if (this.sort_by){
            return (this.sort_by + (this.sort_ascending ? " ASC" : " DESC"))
        }
        return null;
    },
    sortable: function(){
        if (this.baseTable){
            return (!this.reportMap[this.baseTable['value']]['order_by']);
        }
        return false;
    }
  },
  watch: {
      selectedTable: function(){
        this.selectableColumns = [];
        var column;
        var columns;
        if ((this.baseTable != this.selectedTable) && (this.reportMap[this.baseTable['value']]['joins'][this.selectedTable['value']]['aggregate'])){
            if (this.reportMap[this.baseTable['value']]['joins'][this.selectedTable['value']]['ignore_list']){
                columns = {};
                for (let column in this.reportMap[this.selectedTable['value']]['aggregate_columns']){
                    if (column.split('(')[0] != "group_concat"){
                        columns[column] = this.reportMap[this.selectedTable['value']]['aggregate_columns'][column];
                    }
                }
            }
            else{
                columns = this.reportMap[this.selectedTable['value']]['aggregate_columns'];
            }
        }
        else{
            columns = this.reportMap[this.selectedTable['value']]['columns'];
            //also grab all columns from subtables
            for (let sub_table in this.reportMap[this.selectedTable['value']]['sub_tables']){
                for (let sub_table_column in this.reportMap[this.selectedTable['value']]['sub_tables'][sub_table]['columns']){
                    columns[sub_table_column] = this.reportMap[this.selectedTable['value']]['sub_tables'][sub_table]['columns'][sub_table_column];
                }
            }
        }
        //remove already selected columns from column list
        var selectedColumnsDict = {}
        for (var i = 0; i < this.selectedColumns.length; i++){
            selectedColumnsDict[this.selectedColumns[i]['key']] = true
        }
        this.selectableColumns = [];
        //draggable only accepts arrays so convert object to array of objects
        for (let key in columns){
            if (selectedColumnsDict[key] == null){
                column = columns[key];
                column['key'] = key;
                this.selectableColumns.push(column);
            }
        }
        var filter_columns_key = this.reportMap[this.selectedTable['value']]['filterable_columns'];
        if (filter_columns_key){
            this.filterColumns = [];
            for (let key in this.reportMap[filter_columns_key]['columns']){
                var filterColumn = this.reportMap[filter_columns_key]['columns'][key];
                filterColumn['key'] = key;
                this.filterColumns.push({
                    'text': this.reportMap[filter_columns_key]['columns'][key]['name'],
                    'value': filterColumn
                });
            }
        }
      },
      baseTable: function(){
          this.setupData();
      },
      selectedColumns: function(){
          if (!this.reportMap[this.selectedTable['value']]['filterable_columns']){
            this.filterColumns = [];
            //need to change format for form select
            for (var i = 0; i < this.selectedColumns.length; i++){
                this.filterColumns.push({
                    'text': this.selectedColumns[i]['name'],
                    'value': this.selectedColumns[i]
                });
            }
        }
      },
      currentReportTitle: function(){
        this.reportTitle = (this.currentReportTitle ? this.currentReportTitle : "")
      },
      currentReportDescription: function(){
        this.reportDescription = (this.currentReportDescription ? this.currentReportDescription : "")
      },
      currentPage: function(){
          if (this.preview_report){
              this.executeStmt();
          }
      }
  },
  created(){
    this.setupData();
    this.reportTitle = (this.currentReportTitle ? this.currentReportTitle : "")
    this.reportDescription = (this.currentReportDescription ? this.currentReportDescription : "")
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 800px) {
   .container { max-width: 1400px; } 
}

::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
</style>